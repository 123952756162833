import React from 'react';
import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
import { ReactComponent as IconGithub } from './assets/icons/github.svg';
import Logo from './assets/img/logo.png';
import Flor from './assets/img/flor6.webp';
import Soon from './assets/img/soon.png';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <img src={Logo} alt="tecitoFloral" width={200}/>
          </div>
         {/*  <div className="social">
            <a href="https://facebook.com" title="Facebook" target="_blank" rel="noopener noreferrer">
              <IconFacebook className="icon" />
            </a>
            <a href="https://twitter.com" title="Twitter" target="_blank" rel="noopener noreferrer">
              <IconTwitter className="icon" />
            </a>
          </div> */}
        </div>
        <div className="content">
          <div className="logo">
            <img src={Flor} alt="tecitoFloral"  width={300} className='rotating flor1'/>
            <img src={Flor} alt="tecitoFloral"  width={400} className='rotatingAndReverse flor2'/>
            <img src={Flor} alt="tecitoFloral"  width={300} className='rotatingInverse flor3'/>
          </div>
          <div className="title-holder abril-fatface-regular">
          <img src={Soon} alt="tecitoFloral" width={350}/>
          </div>
        </div>
        <div className="footer">
        </div>
      </div>
    );
  }
}

export default App;